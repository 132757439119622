export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

export function formatAddress(address: string) {
  return `${address.substring(0, 4)}...${address.substring(
    address.length - 4,
    address.length
  )}`
}

export function Capitalize(str?: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

export function CapitalizeAll(str?: string) {
  if (str) {
    let words: string[] = []
    const values = str.split(" ")
    for (const value of values) {
      const result = value.charAt(0).toUpperCase() + value.slice(1)
      words = [...words, result]
    }
    return words.join(" ").trim()
  }
}
