import { Link } from "gatsby"
import React, { useRef } from "react"
import * as styles from "./NFTCard.module.scss"
import cn from "classnames"
import useOnScreen from "../../hooks/use-on-screen"

interface NFTCardProps {
  title: string
  imgUrl: string
  ownedBy?: string
  assetType?: string
  by: string
  price: number
  linkTo?: string
  classname?: string
  onScreen?: boolean
}

export const NFTCard = ({
  title,
  imgUrl,
  ownedBy,
  assetType,
  by,
  price,
  linkTo,
  classname,
}: NFTCardProps) => {
  const ref = useRef<HTMLDivElement>()
  const onScreen = useOnScreen(ref)

  return (
    <div className={styles.NFTCardContainer} ref={ref}>
      {linkTo ? (
        <Link to={linkTo}>
          <NFTCardInner
            title={title}
            imgUrl={imgUrl}
            by={by}
            onScreen={onScreen}
            price={price}
            assetType={assetType}
            classname={classname}
          />
        </Link>
      ) : (
        <NFTCardInner
          title={title}
          imgUrl={imgUrl}
          by={by}
          price={price}
          onScreen={onScreen}
          assetType={assetType}
          classname={classname}
        />
      )}
    </div>
  )
}

const NFTCardInner = ({
  title,
  imgUrl,
  by,
  price,
  assetType,
  classname,
  onScreen = true,
}: NFTCardProps) => {
  return (
    <div className={cn(styles.NFTCardInner, classname)}>
      <div className={styles.NFTAssetContainer}>
        {assetType?.indexOf("video") === 0 ? (
          <video
            src={onScreen ? imgUrl : ""}
            width="100%"
            height="100%"
            playsInline
            autoPlay
            preload="none"
            muted
            loop
          ></video>
        ) : (
          <img src={imgUrl} alt={title} />
        )}
      </div>
      <div className={styles.NFTCardInnerInfo}>
        <h1>{title}</h1>
        {/* <span>{`By ${by?.toUpperCase()}`}</span> */}
        <div className={styles.NFTCardPrice}>
          {/* <span>{`OWNED BY ${ownedBy?.toUpperCase()}`}</span> */}
          <span>{`By ${by?.toUpperCase()}`}</span>
          {price ? (
            <span>
              <b>{`${price?.toFixed(2)} ETH`}</b>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  )
}
