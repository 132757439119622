import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { useMemo } from "react"
import { ContentfulIndividualGalleryPageQuery } from "../../../types/gatsby-graphql"
import { shortAddress } from "../../components/ConnectWalletMessage/ConnectWalletMessage"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import { ProfileCard } from "../../components/ProfileCard/ProfileCard"
import ProfileSocialMedias from "../../components/ProfileSocialMedias/ProfileSocialMedias"
import { Tabs } from "../../components/Tabs/Tabs"
import { CapitalizeAll } from "../../utils/string"
import * as styles from "./Gallery.module.scss"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const Gallery = (props: any) => {
  const tabs = ["Artworks Created", "Artworks Collected"]
  const search = props.location.search.split("=")
  const tab = search[1] ? tabs[1] : tabs[0]
  const data: ContentfulIndividualGalleryPageQuery = props.data
  const galleryDirectorName = CapitalizeAll(
    [
      data.contentfulGallerySignup?.galleryRepresentativeFirstName,
      data.contentfulGallerySignup?.galleryRepresentativeMiddleName,
      data.contentfulGallerySignup?.galleryRepresentativeLastName,
    ].join(" ")
  )

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const transformData = useMemo(() => {
    let transformedData: ContentfulIndividualGalleryPageQuery = data
    let _lotList: any[] = []

    data.contentfulGallerySignup?.featuredArtists?.forEach(artist => {
      const lots = data.allContentfulLot?.edges.filter(
        lot => lot.node?.artist?.contentful_id === artist?.contentful_id
      )
      _lotList = [..._lotList, lots]
    })
    if (_lotList.length > 0) {
      transformedData = {
        ...data,
        lots: _lotList.flat(),
      }
    }
    return transformedData
  }, [data])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auctions = useMemo(() => {
    return getAuctionsIds(transformData?.lots)
  }, [data])

  const LoadableGalleryMonitor = loadable(
    () => import("../../components/GalleryMonitor/GalleryMonitor")
  )

  return (
    <div className={styles.galleryContainer}>
      {data.contentfulGallerySignup?.coverImage?.file?.url ? (
        <img
          className={styles.galleryBackground}
          src={data.contentfulGallerySignup?.coverImage?.file?.url!}
          alt={data.contentfulGallerySignup?.coverImage?.title!}
        />
      ) : (
        <div className={styles.galleryBackground}></div>
      )}
      <div className={styles.galleryHeader}>
        <img
          src={data.contentfulGallerySignup?.profilePicture?.file?.url!}
          alt={data.contentfulGallerySignup?.profilePicture?.title!}
        />
        <div className={styles.galleryInner}>
          <div className={styles.galleryDetail}>
            <h1>{`${data.contentfulGallerySignup?.galleryName}`}</h1>
            <h3>@{data.contentfulGallerySignup?.username!}</h3>
            <span className={styles.galleryDirectorInfoContainer}>
              <strong>{`Gallery Director: `}</strong>{" "}
              {` ${galleryDirectorName}`}
            </span>
            <span>
              {
                data.contentfulGallerySignup?.galleryDescription
                  ?.galleryDescription
              }
            </span>
          </div>
          <div className={styles.galleryAditionalInfo}>
            <span>
              <b>Wallet Address:</b>
            </span>
            <span>
              {shortAddress(data.contentfulGallerySignup?.walletAddress!)}
            </span>
            <div className={styles.gallerySocialMedia}>
              <ProfileSocialMedias
                facebook={data.contentfulGallerySignup?.facebookUrl!}
                twitter={data.contentfulGallerySignup?.twitterUrl!}
                instagram={data.contentfulGallerySignup?.instagramUrl!}
                globe={data.contentfulGallerySignup?.website!}
              />
            </div>
            <span>{data.contentfulGallerySignup?.website!}</span>
          </div>
        </div>
      </div>
      <div className={styles.galleryDetailTable}>
        <div className={styles.galleryDetailTableWallet}>
          <span>Wallet Address:</span>
          <span>
            {shortAddress(data.contentfulGallerySignup?.walletAddress!)}
          </span>
        </div>
        <div className={styles.galleryDetailTableWebSite}>
          <span>Website:</span>
          <span>
            <b>{data.contentfulGallerySignup?.website}</b>
          </span>
        </div>
        <div className={styles.galleryDetailTableSocialMedia}>
          <ProfileSocialMedias
            facebook={data.contentfulGallerySignup?.facebookUrl!}
            twitter={data.contentfulGallerySignup?.twitterUrl!}
            instagram={data.contentfulGallerySignup?.instagramUrl!}
            globe={data.contentfulGallerySignup?.website!}
          />
        </div>
      </div>

      <div className={styles.galleryFilters}></div>
      <div className={styles.galleryRepresentedArtistContainer}>
        <h1>Represented Artists</h1>
        <div className={styles.galleryRepresentedArtistInner}>
          {data?.contentfulGallerySignup?.featuredArtists?.map(artist => {
            return (
              <ProfileCard
                name={
                  CapitalizeAll(
                    [artist?.firstName, artist?.lastName].join(" ").trim()
                  )!
                }
                avatar={artist?.profilePicture?.gatsbyImageData}
                account={artist?.username!}
                url={`/artist/${artist?.contentful_id}`}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.galleryArtworks}>
        <h1>All Artworks</h1>
      </div>
      <LoadableGalleryMonitor
        auctions={auctions}
        render={({ result }) => {
          const enrichedData = enricheLotWithAuction(
            transformData.lots || [],
            result?.auctions
          )
          return (
            <div className={styles.galleryNFTList}>
              {enrichedData.map((mapData: any) => {
                const url =
                  mapData?.lot?.auction?.mint?.mediaAsset?.configuration
                    ?.mp4Url ||
                  mapData?.lot?.auction?.mint?.mediaAsset?.media?.file?.url!

                return (
                  <NFTCard
                    title={mapData?.lot?.auction?.mint?.name!}
                    imgUrl={url}
                    assetType={
                      mapData?.lot?.auction?.mint?.mediaAsset?.media?.mimeType!
                    }
                    price={mapData?.auction?.reservePriceInETH!}
                    by={`${[
                      mapData?.lot?.artist?.firstName,
                      mapData?.lot?.artist?.lastName,
                    ]
                      .join(" ")
                      .trimEnd()}`}
                    linkTo={`/artwork/${mapData?.lot?.contentful_id}`}
                  />
                )
              })}
            </div>
          )
        }}
      />

      <div className={styles.galleryNFTList}>
        {/* {data.allContentfulLot.edges.map(edge => {
          return (
            <NFTCard
              title={edge.node.asset?.title!}
              imgUrl={edge.node.asset?.mainMedia?.file?.url!}
              price={0.45}
              by={artistName}
              ownedBy={edge.node.asset?.artist?.username!}
              linkTo={`/artwork/${edge.node.contentful_id}`}
            />
          )
        })} */}
      </div>
    </div>
  )
}

export default Gallery

export const artistQuery = graphql`
  query ContentfulIndividualGalleryPage($contentful_id: String!) {
    contentfulGallerySignup(contentful_id: { eq: $contentful_id }) {
      contentful_id
      email
      facebookUrl
      galleryName
      galleryRepresentativeFirstName
      galleryRepresentativeLastName
      galleryRepresentativeMiddleName
      hasGalleryWorkedWithNfts {
        hasGalleryWorkedWithNfts
      }
      instagramUrl
      location
      twitterUrl
      username
      walletAddress
      website
      galleryDescription {
        galleryDescription
      }
      profilePicture {
        title
        gatsbyImageData
        file {
          url
        }
      }
      coverImage {
        file {
          url
        }
        title
      }
      featuredArtists {
        middleName
        lastName
        username
        firstName
        contentful_id
        profilePicture {
          gatsbyImageData
          file {
            url
          }
          title
        }
        coverImage {
          title
          file {
            url
          }
        }
        experience {
          experience
        }
      }
    }
    allContentfulLot(filter: { auction: { contentfulid: { ne: null } } }) {
      edges {
        node {
          id
          lotId
          ended
          contentful_id
          artist {
            contentful_id
            firstName
            middleName
            lastName
            username
            coverImage {
              file {
                url
              }
              title
              contentful_id
            }
            profilePicture {
              gatsbyImageData
              file {
                url
              }
              title
              contentful_id
            }
          }
          auction {
            ...lotAuctionFragment
          }
        }
      }
    }
  }
`
