import React from "react"
import * as styles from "./ProfileCard.module.scss"
import { IProfileCard } from "./type"
import cn from "classnames"
import { Link } from "gatsby"

export const ProfileCard = ({
  name,
  avatar,
  account,
  edition,
  type,
  className,
  url,
}: IProfileCard) => {
  const staticAvatar =
    "//images.ctfassets.net/ep8tr5awnti1/27CjcJalps8nmI0OjaIZSk/1a9242e089bebccef44439919c4f0866/image.png"

  return (
    <div className={cn(styles.profileCardContainer, className)}>
      {url ? (
        <Link to={url}>
          <img
            src={avatar ? avatar?.images?.fallback?.src : staticAvatar}
            alt={name}
          />
          <div className={styles.profileCardInner}>
            {type && <span>{type}</span>}
            {edition && <span>{`Edition #${edition}`}</span>}
            <h3>{name}</h3>
            <span>{`@${account}`}</span>
          </div>
        </Link>
      ) : (
        <>
          <img
            src={avatar ? avatar?.images?.fallback?.src : staticAvatar}
            alt={name}
          />
          <div className={styles.profileCardInner}>
            {type && <span>{type}</span>}
            {edition && <span>{`Edition #${edition}`}</span>}
            <h3>{name}</h3>
            <span>{`@${account}`}</span>
          </div>
        </>
      )}
    </div>
  )
}
