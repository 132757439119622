import classNames from "classnames"
import React from "react"
import Icon from "../../Icon"

import * as styles from "./ProfileSocialMedias.module.scss"
import { IProfileSocialMedias } from "./types"

const ProfileSocialMedias = ({
  classname,
  instagram,
  facebook,
  twitter,
  globe,
}: IProfileSocialMedias) => {
  return (
    <div className={classNames(classname, styles.profileSocialMedias)}>
      <ul>
        {instagram && (
          <li>
            <a href={instagram} target="_blank" rel="noreferrer">
              <Icon name="instagram" fill="white" />
            </a>
          </li>
        )}
        {twitter && (
          <li>
            <a href={twitter} target="_blank" rel="noreferrer">
              <Icon name="twitter" fill="white" />
            </a>
          </li>
        )}
        {facebook && (
          <li>
            <a href={facebook} target="_blank" rel="noreferrer">
              <Icon name="facebook" fill="white" />
            </a>
          </li>
        )}
        {globe && (
          <li>
            <a href={globe} target="_blank" rel="noreferrer">
              <Icon name="globe" fill="white" />
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

export default ProfileSocialMedias
