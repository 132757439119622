// extracted by mini-css-extract-plugin
export var galleryAditionalInfo = "Gallery-module--galleryAditionalInfo--3a812";
export var galleryArtworks = "Gallery-module--galleryArtworks--d8ad8";
export var galleryBackground = "Gallery-module--galleryBackground--fc339";
export var galleryContainer = "Gallery-module--galleryContainer--3d7bb";
export var galleryDetail = "Gallery-module--galleryDetail--7bda8";
export var galleryDetailTable = "Gallery-module--galleryDetailTable--5d337";
export var galleryDetailTableWallet = "Gallery-module--galleryDetailTableWallet--7997d";
export var galleryDetailTableWebSite = "Gallery-module--galleryDetailTableWebSite--5cb67";
export var galleryDirectorInfoContainer = "Gallery-module--galleryDirectorInfoContainer--bc7f5";
export var galleryFilters = "Gallery-module--galleryFilters--d52e2";
export var galleryHeader = "Gallery-module--galleryHeader--1692e";
export var galleryInner = "Gallery-module--galleryInner--cce83";
export var galleryNFTList = "Gallery-module--galleryNFTList--8f570";
export var galleryRepresentedArtistContainer = "Gallery-module--galleryRepresentedArtistContainer--de664";
export var galleryRepresentedArtistInner = "Gallery-module--galleryRepresentedArtistInner--4d32b";
export var gallerySocialMedia = "Gallery-module--gallerySocialMedia--62ffb";
export var galleryTabs = "Gallery-module--galleryTabs--576fe";