export const enricheLotWithAuction = (lots: any, auctions: any) => {
  return lots?.map((item: any) => {
    const lot = item?.node ? item?.node : item
    const result = {
      lot: lot?.node ? lot?.node : lot,
      auction: auctions?.find((auction: any) => auction.id === lot.auction?.contentfulid),
    }

    return result
  })
}

export const getAuctionsIds = (lots: any) => {
  return lots?.map((lot: any) => lot?.node ? lot?.node?.auction?.contentfulid : lot?.auction?.contentfulid)
}

export interface AssociateWalletWithUserRequestData {
  wallet: string
  name: string
  email: string
  partner: string
  partnerID: string
  eventType: string
}

export const associateWalletWithUser = async (
  requestData: AssociateWalletWithUserRequestData
) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(requestData),
  }
  await fetch('/api/wallets/events', requestOptions)
}
